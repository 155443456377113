import Vue from 'vue'
import VueRouter from 'vue-router'

import { getToken } from '@/utils/storage'
import APP_Layout from '@/views/APP_Layout'

const APP_GameDetail = () => import('@/views/APP_GameDetail')
const APP_Growth = () => import('@/views/APP_Growth')
const APP_Game = () => import('@/views/APP_Game')
const APP_User = () => import('@/views/APP_User')
const APP_PayMent = () => import('@/views/APP_PayMent')
const APP_AccountSetting = () => import('@/components/APP_AccountSetting.vue')
const APP_MessageCenter = () => import('@/components/APP_MessageCenter.vue')
const APP_GameChange = () => import('@/components/APP_GameChange.vue')
const APP_MyGame = () => import('@/components/APP_MyGame.vue')
const APP_ExchangeRecord = () => import('@/components/APP_ExchangeRecord.vue')
const APP_loginSuccess = () => import('@/components/APP_loginSuccess.vue')
const APP_RechargeRecord = () => import('@/components/APP_RechargeRecord.vue')
const APP_SearchGame = () => import('@/views/APP_SearchGame.vue')
const APP_MembershipRenewal = () => import('@/components/APP_MembershipRenewal.vue')
const APP_PastDue = () => import('@/components/APP_PastDue.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/app',
    component: APP_Layout,
    redirect: '/app/APP_Game',
    children: [
      { path: '/app/APP_Game', component: APP_Game },
      { path: '/app/APP_PayMent', component: APP_PayMent },
      { path: '/app/APP_User', component: APP_User }
    ]
  },
  { path: '/app/APP_GameDetail/:id', component: APP_GameDetail, meta: { title: '游戏详情', name: '游戏详情' } },
  { path: '/app/APP_AccountSetting', component: APP_AccountSetting, meta: { title: '账号设置', name: '账号设置' } },
  { path: '/app/APP_MessageCenter', component: APP_MessageCenter, meta: { title: '消息中心', name: '消息中心' } },
  { path: '/app/APP_GameChange/:id', component: APP_GameChange, meta: { title: '游戏兑换', name: '游戏兑换' } },
  { path: '/app/APP_SearchGame', component: APP_SearchGame, meta: { title: '搜索游戏', name: '搜索游戏' } },
  { path: '/app/APP_MyGame', component: APP_MyGame, meta: { title: '我的游戏', name: '我的游戏' } },
  { path: '/app/APP_ExchangeRecord', component: APP_ExchangeRecord, meta: { title: '兑换记录', name: '兑换记录' } },
  { path: '/app/APP_loginSuccess', component: APP_loginSuccess, meta: { title: '登入成功', name: '登入成功' } },
  { path: '/app/APP_RechargeRecord', component: APP_RechargeRecord, meta: { title: '充值记录', name: '充值记录' } },
  { path: '/app/APP_Growth', component: APP_Growth, meta: { title: '成长福利', name: '成长福利' } },
  { path: '/app/APP_MembershipRenewal', component: APP_MembershipRenewal, meta: { title: '包服卡购买', name: '包服卡购买' } },
  { path: '/app/APP_PastDue', component: APP_PastDue, meta: { title: '过期记录', name: '过期记录' } },
]
const router = new VueRouter({
  mode: 'history', // 去掉url中的#
  routes
})
// 每个路由即将被访问匹配时，都会调用beforeEach的回调函数
// to：往哪去，要去的路由信息对象（路径，参数）
// from：从哪来，从哪个地址过来的
// next：是否放行
// （1）next() 放行
// （2）next(path) 拦截某个路由路径

/** 解决跳转重复路由报错问题 */
const routerPush = router.push;
router.push = path => {
  // 判断下当前路由是否就是要跳转的路由
  if (router.currentRoute.fullPath.includes(path)) return;
  return routerPush.call(router, path);
}

const whiteList = ['/login', '/register']

router.beforeEach((to, from, next) => {
  // const token = getToken()
  // if (token) {
  //   next()
  // } else {
  //   if (whiteList.includes(to.path)) {
  //     next()
  //   } else {
  //     next('/login')
  //   }
  // }
  if (to.meta.title == '游戏详情') {
    document.title = '游戏详情'
  } else if (to.meta.title == '账号设置') {
    document.title = '账号设置'
  } else if (to.meta.title == '消息中心') {
    document.title = '消息中心'
  } else if (to.meta.title == '搜索游戏') {
    document.title = '搜索游戏'
  } else {
    const title = window.localStorage.getItem('title1')
    document.title = title
  }
  next()
})
// 防止跳转报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}
export default router
